<style type="text/css">
  @import "~@/assets/css/table.css";
</style>
<style type="text/css" scoped>
  .base-header-box {
    width: 130px;
  }
</style>
<script>
  export default {
    components: {},
    props: {
      items: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    data() {
      return {
        isActive: 1,
        pathArr: [],
        urlArr: [{
          childUrl: [{
              index: 1,
              url: "/admin/examinee",
              name: "注册用户管理",
            },
            {
              index: 2,
              url: "/admin/userAccountDeal",
              name: "考生申诉处理",
            },
            {
              index: 3,
              url: "/admin/img/handle",
              name: "考生照片审核",
            },
            {
              index: 4,
              url: "/admin/edu/handle",
              name: "考生学历审核",
            },
          ]
        }, {
          childUrl: [{
            index: 5,
            url: "/admin/institutionManage",
            name: "机构用户管理",
          }, {
            index: 6,
            url: "/admin/userAdmin",
            name: "管理员用户管理",
          }, {
            index: 7,
            url: "/admin/examinationDepartment",
            name: "审核权限设置",
          }, ]
        }]

      };
    },
    mounted() {
      this.isActive = this.$route.path;
      this.initUrl()
    },
    methods: {
      isNowUrl(url) {
        if (typeof url == "string") {
          return this.isActive == url;
        }
      },
      initUrl() {
        this.pathArr = []
        if (this.userInfo && this.userInfo.roleNum == 10) {
          this.urlArr[1].childUrl.push({
            index: 8,
            url: "/admin/userAdminHide",
            name: "超级管理员用户管理",
          })
        }
        let newUrl = []
        this.urlArr.forEach(k => {
          if (k.childUrl.filter(v => {
              return v.url == this.isActive
            })[0]) {
            newUrl.push(k.childUrl)
          }
        })

        this.pathArr.unshift(...newUrl[0])
      }
    },
  };
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box flexList justify-content-between">
        <div class="flexList col-4 p-0">
          <a :href="obj.url" class="p-0 mr-4" v-for="obj in pathArr" :key="obj.index">
            <div class="base-header-box" :class="{active: isNowUrl(obj.url)}">
              {{ obj.name }}
            </div>
          </a>
           <slot></slot>
        </div>
        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
