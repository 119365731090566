<template>
  <div style="display: flex">
    <div v-for="(v, i) in data" :key="i" style="margin-right: 10px;display: flex">
      <yz-select
          v-if="v.type == 'select'"
          :dict="v.dict"
          :bound="v.bound"
          v-model="query"
          @change="changeEv"
          style="max-width: 150px"
      ></yz-select>
      <input
          class="form-control h30 input-width"
          v-if="v.type == 'input'"
          :placeholder="v.title"
          @change="changeEv"
          v-model="query[v.bound]"
          style="max-width: 150px"
      />
      <slot></slot>
    </div>
    <button type="button" class="btn btn-info h30 flexList mr-2" @click="seach">
      <i class="iconfont mr-2 icon-mb-search"></i>查询
    </button>
  </div>
</template>

<script>
import YzSelect from "@/components/form/yzSelect.vue";

export default {
  name: "ErsAdminYzsearch",
  components: {
    YzSelect,
  },
  model: {
    event: "input",
    prop: "query",
  },
  props: {
    query: Object,
    data: Array,
  },
  data() {
    return {
      first: true,
    };
  },
  mounted() {
    this.updateData();
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if (this.first) {
          this.updateData();
          this.first = false;
        }
      },
    },
    query: {
      deep: true,
      handler() {
      },
    },
  },
  methods: {
    updateData() {
      this.data.forEach((item) => {
        if (item.type == "select") {
          item['oldDict'] = item.dict
          this.query[item.bound[0]] = "";
          if (item.dict.length == 0 || (item.dict[0] && !item.dict[0].name.includes("请选择"))){
            item.dict.unshift({name: "请选择" + item.title, value: ""});
          }
        }
      });
    },
    changeEv() {
      for (let i in this.data) {
        if (this.data[i].type == 'select') {
          let f = this.data[i].filter
          if (this.query[f]) {
            this.data[i].dict = this.data[i].oldDict.filter(item => {
              return !item.details || item.details[f] == this.query[f]
            })
          }
        }
      }
      this.$forceUpdate()
      this.$emit("input", this.query);
      this.$emit("change", this.query);
    },
    seach() {
      this.$emit("seachList", this.query);
    },
  },
};
</script>

<style lang="scss" scoped></style>
