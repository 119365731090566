import store from '@/state/store.js';
import {
  getRequest
} from "@/libs/axios";
import {
  getNowExam
} from "@/api/admin/exam/examRecord.js"
// 公共字典数据

// 字典转换器
const coverDict = function (items, conf, i = 0) {
  let res = [];
  items.forEach((item) => {
    let resItem = {};
    resItem["value"] = item[conf[i][0]];
    resItem["name"] = item[conf[i][1]];
    resItem["details"] = item;
    if (conf && conf.length - 1 > i) {
      resItem["children"] = coverDict(item.children, conf, i + 1);
    }
    res.push(resItem);
  });
  return res;
};
// 多个字典转换器
const coverDicts = function (items, ...conf) {
  if (items) {
    return coverDict(items, conf);
  }
  return items;
};

// 自定义字典配置对应的方法
const dictFunc = {
  ssjg: [
    function () {
      return getRequest("/yethan/data/org/easyList",{},false);
    },
    ["jgdm", "jgmc"],
  ],
  ssdw: [
    function () {
      return getRequest("/yethan/data/orgDepartment/easyList",{},false);
    },
    ["bmdm", "bmmc"],
  ],
  bm: [
    function () {
      return getRequest("/yethan/data/orgUnits/easyList",{},false);
    },
    ["dwdm", "dwmc"],
  ],
  // 收费账户
  sh: [
    function () {
      return getRequest("/yethan/pay/merchant/easyList",{},false);
    },
    ["shbm", "shmc"],
  ],
  // 考试大类
  ksdl: [
    function () {
      return getRequest("/yethan/register/mainType/easyList",{},false);
    },
    ["lbbm", "lbmc"],
  ],
  // 考试二级类别
  ejlb: [
    function () {
      return getRequest("/yethan/register/secondType/easyList",{},false);
    },
    ["flbm", "flmc"],
  ],
  ksxx: [
    function () {
      return getRequest("/yethan/register/examRecord/easylist",{},false);
    },
    ["ksbmbh", "ksmc"],
  ],
  // 科目
  km: [
    function () {
      return getRequest("/yethan/register/subject/easyList",{},false);
    },
    ["kmdm", "kmmc"],
  ],
  xk: [
    function () {
      return getRequest("/yethan/register/classification/easyList",{},false);
    },
    ["xkdm", "xkmc"],
  ],
  gddl: [
    function () {
      return getRequest("/yethan/workOrderType/big/list",{},false);
    },
    ["sid", "sortName"],
  ],
  gdxl: [
    function () {
      return getRequest("/yethan/workOrderType/small/list",{},false);
    },
    ["tid", "typeName"],
  ],
  bkdw: [
    function () {
      return new Promise(re => {
        getNowExam().then(res => {
          if (res.status) {
            getRequest("/yethan/register/unitsPosition/dwList", {
              ksbmbh: res.data.ksbmbh
            },false).then(res => {
              re(res);
            })
          }
        })
      })
    },
    ["dwdm", "dwmc"],
  ],

  bkzw: [
    function () {
      return new Promise(re => {
        getNowExam().then(res => {
          if (res.status) {
            getRequest("/yethan/register/unitsPosition/zwList", {
              ksbmbh: res.data.ksbmbh
            },false).then(res => {
              re(res);
            })
          }
        })
      })
    },
    ["zwdm", "zwmc"],
  ],
  zgxlm: [
    function () {
      return new Promise(r => {
        let sfzjh = store.state.choosePeople.sfzjh
        if (sfzjh) {
          getRequest(`/yethan/userInfo/${sfzjh}/userInfoEducation`,{},false).then(res => {
            if (res.status) {
              res.data.forEach(v => {
                v.name = v.byyxxhdw + v.xlmc + '(' + (v.xlshzt == 0 ? '待审' : v.xlshzt == 1 ? '审核通过' : '审核未通过') + ')'
              })
              r(res)
            }
          })
        }

      })
    },
    ["xlm", "xlmc"],
  ],
  shdw: [
    function () {
      return getRequest(`/yethan/checkPower/units/departmentAndOrg/list`,{},false)
    },
    ["dwdm", "dwmc"],
  ],
};
const coverConfig = {
  dict_xzqh: [
    ["ssdm", "ssmc"],
    ["djsdm", "djsmc"],
    ["qxdm", "qxmc"],
  ],
  dict_mz: ["mzm", "mzmc"],
  dict_xb: ["xbm", "xbmc"],
  dict_zzmm: ["zzmmm", "zzmmjc"],
  dict_hyzt: ["hyztm", "hyztmc"],
  dict_xl: ["xlm", "xlmc"],
  dict_xw: ["xwm", "xwmc"],
  dict_sfzjlx: ["sfzjlxm", "sfzjlxmc"],
  dict_gatqw: ["gatqwm", "gatqwmc"],
  dict_gjdq: ["gjdqm", "gjdqmc"],
  dict_xyzj: ["xyzjm", "xyzjmc"],
  dict_xxfs: ["xxfsm", "xxfsmc"],
  dict_jkzk: ["jkzkm", "jkzkmc"],
};

// 获取字典都要走的方法
export const getDicts = (...dicts) => {
  return new Promise((resolve) => {
    getRequest(`/yethan/public/dict/${dicts.join(",")}`, {},false).then((res) => {
      let resData = {};
      for (let k in coverConfig) {
        if (res.data[k]) {
          if (k == "dict_xzqh") {
            resData[k] = coverDicts(res.data[k], ...coverConfig[k]);
          } else {
            resData[k] = coverDicts(res.data[k], coverConfig[k]);
          }
        }
      }
      let func = [];
      let keys = [];
      let covers = [];
      dicts.forEach((item) => {
        if (dictFunc[item]) {
          func.push(dictFunc[item][0]());
          keys.push(item);
          covers.push(dictFunc[item][1]);
        }
      });
      if (func.length == 0) {
        resolve(resData);
      }
      Promise.all(func).then((result) => {
        for (let i in keys) {
          resData[keys[i]] = coverDicts(result[i].data, covers[i]);
          resData = Object.assign(resData, {
            yhlb: [{
                name: "机构用户",
                value: "org"
              },
              {
                name: "市州负责人",
                value: "city"
              },
              {
                name: "管理员用户",
                value: "admin"
              },
            ],
            gdzycd: [{
                name: "普通",
                value: "normal"
              },
              {
                name: "重要",
                value: "important"
              },
            ],
            jgxzlx: [
              {
                name: "主管部门",
                value: "主管部门"
              },
              {
                name: "报考单位",
                value: "报考单位"
              },
              {
                name: "其他",
                value: "其他"
              },
            ]
          });
          resolve(resData);
        }
      });
    });
  });
};

export const getDictSrc = params => {
  return getRequest("/yethan/dict/tableInfo/dictPage", params,false);
};


export const getUnits = function (params) {
  return getRequest("/yethan/data/orgUnits/easyList", params,false)
}