<!-- 考试大类 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<script>
import Layout from "@/views/layouts/main";
import UserHeader from "../user-header.vue";
import {
  addUserManagement,
  getUserManagementDetails,
  updateUserManagement,
  deleteUserManagement,
  updateUserManagementKyzt,
  getUserDetails,
  updatePermission,
  updatePassword,
} from "@/api/admin/user/institutionManage.js";
import { getDicts } from "@/api/common/dict.js";
import YzSearch from "@/components/form/yzSearch.vue";
import {getStatisticalPage} from "@/api/admin/user/institutionManage";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    UserHeader,
    YzSearch,
  },
  data() {
    return {
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "用户管理",
          active: true,
        },
      ],
      checked: true,
      defaultvalue: 3,
      currentpage: 3,
      showmodal: false,
      tableList: [],
      pageData: {
        nowPage: 1,
        pageSize:20,
        total: 0,
        sczt: 0,
      },
      showPromission: false,
      dict: {},
      form: {},
      search: [],
      params: {}
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val;
      this.getList();
    },
    getList() {
      getStatisticalPage(this.pageData).then((res) => {
        if (res.status) {
          this.tableList = res.data;
          this.pageData.total = res.total;
          this.pageData.pageNum = res.pageNum;
          this.pageData.pageSize= res.pageSize;
        }
      });
    },
    searchClick() { //搜索时先将分页的页码归1
      this.pageData.pageNum = 1; //当前页				
      this.getList() //渲染数据的函数
    },
    addUser() {
      addUserManagement(this.form).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "新增成功!",
          });
          this.showmodal = false;
          this.getList();
        }
      });
    },
    editUser(yhzh) {
      getUserManagementDetails(yhzh).then((res) => {
        if (res.status) {
          this.form = res.data;
          this.showmodal = true;
        }
      });
    },

    updateUser() {
      updateUserManagement(this.form.yhzh, this.form).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "更新成功!",
          });
          this.showmodal = false;
          this.getList();
        }
      });
    },
    deleteUser(yhzh) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteUserManagement(yhzh, 1).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        });
      });
    },
    ztUpdate(yhzh, kyzt) {
      updateUserManagementKyzt(yhzh, kyzt).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "更新成功!",
          });
          this.getList();
        }
      });
    },
    getPermission(yhzh) {
      getUserDetails(yhzh).then((res) => {
        if (res.status) {
          this.form = res.data;
          this.showPromission = true;
        }
      });
    },
    updatePermission(mid, mkqx) {
      updatePermission(mid, { mkqx: mkqx }).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "更新成功!",
          });
          this.showPromission = false;
          this.getList();
        }
      });
    },
    startUpdate(yhzh, mm) {
      updatePassword(yhzh, mm).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "更新成功",
          });
        }
      });
    },
    sfzjChange() {
      if (this.form.sfzjh && this.form.sfzjh.length >= 18) {
        this.form.mm = this.form.sfzjh.substring(12);
        this.$forceUpdate();
      }
    },
  },
  mounted() {
    this.getList();
    getDicts("dict_xzqh", "ssjg", "ssdw", "bm").then((res) => {
      this.dict = res;
      this.dict.yhlb = [this.dict.yhlb[0]];
      this.search = [
        {
          title: "机构",
          bound: ["jgdm"],
          type: "select",
          dict: JSON.parse(JSON.stringify(res.ssjg)),
        },
        {
          title: "单位",
          bound: ["dwdm"],
          type: "select",
          filter: "jgdm",
          dict: JSON.parse(JSON.stringify(res.bm)),
        },
      ];
    });
  },
};
</script>

<template>
  <Layout>
    <UserHeader :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <yz-search
                  :data="search"
                  v-model="pageData"
                  @seachList="searchClick()"
                ></yz-search>
                <a href="/admin/baseOrganization">
                  <b-button
                      v-b-modal.addInstiUser
                      variant="outline-info"
                      class="flexList mr-2 condition"
                  ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                  >管理单位</b-button
                  >
                </a>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%">序号</th>
                    <th style="width: 15%">机构代码</th>
                    <th style="width: 15%">所属机构</th>
                    <th style="width: 15%">单位代码</th>
                    <th style="width: 15%">所属单位</th>
                    <th style="width: 15%">账号数</th>
                    <th style="width: 5%;">管理员数</th>
                    <th style="width: 8%;">修改人</th>
                    <th style="width: 6%;">修改时间</th>
                    <th style="width: 7%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in tableList" :key="index">
                    <td>{{  (pageData.pageNum - 1) * pageData.pageSize +  index + 1 }}</td>
                    <td>{{ obj.jgdm }}</td>
                    <td>{{ obj.jgmc }}</td>
                    <td>{{ obj.dwdm }}</td>
                    <td>
                      <a :href="'/admin/institutionManage?dwdm=' + obj.dwdm" class="font-blue">{{ obj.dwmc }}</a>
                    </td>
                    <td>{{ obj.zrs }}</td>
                    <td>{{ obj.glyzrs }}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td class="tab-icon">
                      <a :href="'/admin/institutionManage?dwdm=' + obj.dwdm">
                        <el-button type="primary" class="h30" size="mini">管理账户</el-button>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination
                @size-change="handleSizeChange"
                background
                @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum"
                :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next"
                :total="pageData.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
